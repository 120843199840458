import React from 'react';

interface Overrides {
  values: Record<string, string>;
  set(key: string, value: string): void;
  reset(key: string): void;
  clearAll(): void;
}

export interface DevelopmentToolsContextT {
  overrides: Overrides;
  disabled?: boolean;
  isModalOpen: boolean;
  openModal(): void;
  closeModal(): void;
}

const noop = () => {};

const defaultOverrideContext: DevelopmentToolsContextT = {
  overrides: {
    values: {},
    set: noop,
    reset: noop,
    clearAll: noop,
  },
  disabled: true,
  isModalOpen: false,
  openModal: noop,
  closeModal: noop,
};

export const DevelopmentToolsContext =
  React.createContext<DevelopmentToolsContextT>(defaultOverrideContext);

DevelopmentToolsContext.displayName = 'DevelopmentToolsContext';
